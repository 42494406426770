.form-message {
  border-radius: var(--border-radius);
  padding: var(--padding-md) var(--padding-xl);
  margin-bottom: var(--margin-md);
}
.form-message.blue {
  background: var(--bg-blue);
  color: var(--text-on-blue);
}
.form-message.green {
  background: var(--bg-green);
  color: var(--text-on-green);
}
.form-message.yellow {
  background: var(--bg-yellow);
  color: var(--text-on-yellow);
}
.form-message.orange {
  background: var(--bg-orange);
  color: var(--text-on-orange);
}
.form-message.red {
  background: var(--bg-red);
  color: var(--text-on-red);
}
.form-message.gray {
  background: var(--bg-gray);
  color: var(--text-on-gray);
}
.form-message.purple {
  background: var(--bg-purple);
  color: var(--text-on-purple);
}
.form-message.pink {
  background: var(--bg-pink);
  color: var(--text-on-pink);
}
.form-message.cyan {
  background: var(--bg-cyan);
  color: var(--text-on-cyan);
}

main {
  border-radius: var(--border-radius);
  padding-bottom: 4rem;
  padding-top: 1rem;
}
main .like-disabled-input {
  background: transparent;
}
main .blocks > div {
  display: inline-block;
  vertical-align: top;
}
main table.table {
  border: none;
  width: initial;
  /*margin: 10px 15px 10px 0;*/
  margin: 0;
  padding: 0;
  margin-left: 15px;
  margin-bottom: 1rem;
}
main table.table th {
  background-color: var(--gray-50);
  font-weight: bold;
}
main table.table th,
main table.table td {
  padding: 0.5rem 1rem;
  border: 1px solid #E2E6E9;
  line-height: 1em;
}
main table.table thead th {
  text-align: center;
}
main table.table tbody th {
  text-align: left;
  vertical-align: middle;
}
main table.payment-method tbody td {
  margin: 0;
  padding: 0;
  border-right: none;
  border-left: none;
  border-bottom: none;
}
main table.payment-method tbody td table.table {
  margin: 0;
}
main table.payment-method tbody td table.table td {
  border: 1px solid #E2E6E9;
  padding: 0.5rem 1rem;
}
main .page-footer img {
  height: 28px;
  padding: 0 7px;
}

/* modal dialog */
.modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal .modal-body div.form-group {
  margin-bottom: 0.75rem;
}
.modal .modal-body div.form-group > div {
  display: block !important;
}
.modal .modal-body div.form-group label {
  display: block;
  margin-bottom: 0px;
}
.modal .modal-body .demo-instructions .copyable:hover {
  cursor: pointer;
}
.modal .modal-body .demo-instructions .cc-container {
  max-height: 137px;
  overflow: auto;
  border: 1px solid #E2E6E9;
}
.modal .modal-body .demo-instructions .cc-container table thead {
  position: sticky;
  top: 0;
  background-color: white;
}
.modal .modal-body .demo-instructions .cc-container table thead tr {
  box-shadow: inset 0 -2px #E2E6E9;
}
.modal .modal-body .demo-instructions .cc-container table {
  border: none;
}
.modal .modal-body .modal-footer {
  /* This is my fake custom footer */
  background-color: white;
  margin-right: -2px;
  margin-left: -2px;
}
.modal .modal-body th {
  background-color: var(--gray-50);
  font-weight: bold;
}
.modal .modal-body .notice {
  background: var(--bg-blue);
  color: var(--text-on-blue);
  padding: var(--padding-lg);
  margin: var(--margin-md) calc(-1 * var(--padding-lg));
}
.modal .modal-body .notice.align-top {
  margin-top: calc(-1 * var(--margin-md));
}
.modal .modal-body .notice .header {
  font-size: 1rem;
  color: var(--text-on-blue);
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.modal .modal-body div#iframe_holder.authorize-net {
  width: 100%;
  max-width: 1000px;
  /* hack to force a single column on the authorize.net payment iframe */
  padding: 0 5px;
  /*
  width:calc(100% + (var(--padding-lg) * 2));
  max-width:1000px;
  margin:calc(var(--padding-md) * -1) calc(var(--padding-lg) * -1);
  */
}

.form-page main div[data-fieldname$=acknowledgement] .form-group {
  margin-bottom: 0;
}
.form-page main div[data-fieldname$=acknowledgement] .form-group > div {
  display: inline-block;
}

main .form-page .row.form-section {
  border-top: 1px solid #E2E6E9;
}
main .form-page .row.form-section .section-head {
  font-weight: bold;
  font-size: var(--text-xl);
  padding: var(--padding-md) 0;
}
main .form-page .row.form-section.visible-section:first-child {
  border-top: none;
}
main .like-disabled-input {
  color: var(--text-color);
}
main div[data-fieldname=advertiser_information] .ql-editor,
main div[data-fieldname=campaign_options] .ql-editor,
main div[data-fieldname=campaign_location] .ql-editor,
main div[data-fieldname=contract_amount] .ql-editor,
main div[data-fieldname=parties] .ql-editor,
main div[data-fieldname=submission_metadata] .ql-editor {
  white-space: initial;
  color: #4C5A67;
}

#freeze {
  z-index: 3020;
  bottom: 0;
  opacity: 0;
  background-color: var(--bg-color);
}
#freeze.in {
  opacity: 0.5;
}
#freeze .freeze-message-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: grid;
  place-content: center;
}
#freeze .freeze-message-container .freeze-message {
  color: var(--text-color) !important;
}

.form-group > div.control-input-wrapper .help-box:empty {
  display: none;
}

input.form-control,
select.form-control {
  background-color: var(--bg-yellow) !important;
}

div[data-fieldname=signature] .signature-field {
  min-width: -moz-fit-content;
  min-width: fit-content;
  background: var(--bg-yellow);
}
div[data-fieldname=signature] .signature-field canvas {
  background: var(--bg-yellow) !important;
}

div.form-section [data-fieldname=parties] div.form-group label.control-label,
div.form-section [data-fieldname=signature] div.form-group label.control-label,
div.form-section [data-fieldname=submission_metadata] div.form-group label.control-label,
div.form-section div.form-group label {
  display: none;
  margin-bottom: 0px;
}
div.form-section [data-fieldname=full_name] div.form-group label, div.form-section:last-child div.form-group label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
div.form-section div.form-group label.reqd,
div.form-section div.acknowledgement div.form-group label {
  display: inline-block;
}

.acknowledgement {
  margin-right: 2em;
}
.acknowledgement, .acknowledgement div, .acknowledgement input {
  display: inline-block;
  width: initial;
  white-space: initial;
}

.page-header-actions-block {
  white-space: nowrap !important;
  margin-right: 0.5rem;
}

.sticky-header-actions {
  position: fixed;
  z-index: 5;
  top: 5px;
  left: 5px;
  transition: opacity 0.3s, visibility 0.3s;
}
.sticky-header-actions .btn {
  box-shadow: 0 1px 4px rgba(27, 46, 91, 0.5);
}

.initials {
  font-weight: bold;
  font-style: italic;
  padding: 2px 4px;
  border: 1px solid var(--text-on-yellow);
  background-color: var(--bg-yellow);
  border-radius: 2px;
}

nav.navbar,
footer.web-footer .footer-logo-extension,
footer.web-footer .footer-grouped-links,
footer.web-footer .footer-links,
footer.web-footer .footer-info .footer-powered {
  display: none;
}

footer.web-footer {
  padding: 2rem 0;
}

:root {
  --text-xl: 20px;
  --error-border: var(--text-on-red);
}

.frappe-control:not([data-fieldtype=MultiSelectPills]):not([data-fieldtype="Table MultiSelect"]) input,
.frappe-control:not([data-fieldtype=MultiSelectPills]):not([data-fieldtype="Table MultiSelect"]).has-error input {
  border: 1px solid var(--border-color);
}

div.shepherd-element {
  box-shadow: 0 1px 4px rgba(27, 46, 91, 0.5);
}
div.shepherd-element:not(.instruction-tour) {
  z-index: 100;
}
div.shepherd-element .shepherd-arrow::before {
  background: var(--red-500) !important;
}
div.shepherd-element .shepherd-content {
  background: #FFFFFF;
}
div.shepherd-element .shepherd-content .shepherd-header {
  background: #1b2e5b;
}
div.shepherd-element .shepherd-content .shepherd-header h3 {
  color: #f2a21d;
  font-weight: bold;
}
div.shepherd-element .shepherd-content .shepherd-text {
  padding: var(--padding-md) var(--padding-lg);
}
div.shepherd-element .shepherd-content .btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--border-radius-sm);
}
div.shepherd-element .shepherd-content .shepherd-button.btn-secondary {
  background-color: #F9FAFA;
  color: #192734;
}
div.shepherd-element .shepherd-content .shepherd-button.btn-secondary:not(:disabled):hover {
  background-color: #e4e9e9;
  border-color: #DDE3E3;
}
div.shepherd-element .shepherd-content .shepherd-button.btn-primary:not(:disabled):hover {
  background-color: #93651A;
  border-color: #a8741f;
}
div.shepherd-element .shepherd-content .shepherd-footer {
  padding: 0.75rem;
  border-top: 1px solid var(--border-color);
}

.btn-primary:not(.disabled):not(:disabled):hover {
  background-color: #93651A;
  border-color: #a8741f;
}

.btn.disabled, .btn:disabled {
  opacity: 1;
  filter: grayscale(50%);
}

main .shepherd-target:not(button) {
  border: 2px solid var(--text-on-yellow) !important;
}

.show-errors .has-error input,
.show-errors .has-error .signature-field {
  border: 2px solid var(--red) !important;
}

#freeze.in {
  opacity: 0.8;
}

#freeze .spinner {
  position: relative;
  animation: fgear 4s linear infinite;
}

@keyframes fgear {
  100% {
    transform: rotate(-360deg);
  }
}
@media (max-width: 767px) {
  /* Split Payment Page */
  div[data-path="starstix_contract/split_payment"] #iframe_holder,
  div[data-path="starstix_contract/demo/split_payment"] #iframe_holder {
    max-width: 449px;
    margin: 0 auto;
  }
}
@media (max-width: 991px) {
  .modal .modal-body div#iframe_holder.authorize-net {
    padding: 0;
    max-width: 449px;
  }
}
@media (min-width: 768px) {
  .form-layout .form-column.col-sm-12 > form > .input-max-width {
    max-width: 50%;
    padding-left: 15px;
  }
}
@media (max-width: 768px) {
  main.container > div.d-flex {
    display: initial !important;
  }
  main.container > div.d-flex .page-header-actions-block {
    margin-right: 0px;
    margin-bottom: 5px;
  }
}
@media (max-width: 575px) {
  .form-layout .form-column {
    padding-right: 0px;
    padding-left: 0px;
  }
  .modal .modal-body div#iframe_holder.authorize-net {
    margin: 0 auto;
    position: relative;
  }
}
@media (max-width: 400px) {
  .shepherd-element {
    max-width: 100vw;
  }
}